<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9156 9.34739e-06H0.521121C0.225852 9.34739e-06 0 0.225852 0 0.52113C0 0.62533 0.0347733 0.729529 0.086873 0.816399L4.32507 6.89578V13.4789C4.32507 13.7741 4.55091 14 4.84619 14H7.5906C7.88587 14 8.11172 13.7742 8.11172 13.4789L8.1116 6.89578L12.3498 0.816399C12.5061 0.57323 12.454 0.260508 12.2281 0.0868731C12.1239 0.0173262 12.0197 0 11.9156 0L11.9156 9.34739e-06ZM7.15633 6.42678C7.0869 6.51366 7.06946 6.61786 7.06946 6.72205V12.9578H5.36726V6.72205C5.36726 6.61785 5.33249 6.51365 5.28039 6.42678L1.52854 1.04219H10.9429L7.15633 6.42678Z" fill="#1E70BB"/>
  </svg>
</template>

<script>
export default {
  name: "IconFilter"
}
</script>

<style scoped>

</style>